import { v4 as uuid } from 'uuid';
import store from '../store';
import { UserStoreModule, TemplateRegistry, UserTableV1, SiteDataConfigManagerV1, PropertyManagerV1, NavbarPageManagerV1, ManageCookiePoliciesV1, SmartTagManagerV1, FilterConfigurationManagerV1, RestrictionListManagerV1, SellsideRestrictedListV1, RolePermissionTableV1, SiteDataTranslationsV1, ReportManagerV1 } from '@bluematrix/ui-library';

function processCustomPage(navbarEntry, props) {
    const customPage = navbarEntry.siteCustomPage;
    if(customPage){
        console.debug('pageUuid', customPage.pageUuid);
        props.pageUuid = customPage.pageUuid;
        props.customPageTemplate = customPage.templateKey;
        if(customPage.iframeUrl) {
            props.iframeUrl = customPage.iframeUrl;
        }
    }
    if(navbarEntry.permissionUserType === 'PUBLIC'){
        props.isPublic = true;
    }
    return props;
}

function getCustomPageComponent(templateKey, fallbackTemplateKey) {
    let template = TemplateRegistry.getTemplate(templateKey);
    if(!template && fallbackTemplateKey){
        console.warn(`No Template found for ${templateKey} setting as fallback template ${fallbackTemplateKey}`);
        template = TemplateRegistry.getTemplate(fallbackTemplateKey);
    }
    if(!template){
        console.error(`No Template found for ${templateKey} setting as default template NOT_FOUND`);
        template = TemplateRegistry.getTemplate('NOT_FOUND');
    }
    return template;
}

export default {
    // eslint-disable-next-line
    generate(navbarEntry, showInNavbar, parent, home) {
        const hasAuthority = store.getters[`${UserStoreModule.name}/hasAuthority`];
        console.debug('Generating navbarEntry for ', navbarEntry.fallbackName, navbarEntry.navbarEntryUuid, navbarEntry.pageType, hasAuthority('ROLE_PORTAL_ADMIN'), hasAuthority('ROLE_PORTAL_USER_ADMIN'));
        const getI18Key = (key = '') => key.startsWith('$vuetify.')? key : `$vuetify.client.${key}`;
        const i18nKey = navbarEntry.nameI18nKey ? getI18Key(navbarEntry.nameI18nKey) : undefined;
        const uniqueID = navbarEntry.navbarEntryUuid || `no-trace-${uuid()}`; //if we have to generate the ID then there's no way to trace page access.
        const pageType = navbarEntry.pageType;
        const pageProps = {
            id: uniqueID,
            name: uniqueID,
            pageType: pageType,
            fallbackName: navbarEntry.fallbackName,
            i18nKey: i18nKey,
            showInNavbar: showInNavbar,
            lang: navbarEntry.lang,
            hideNavLink: navbarEntry.hideNavLink,
            props: {
                navbarEntryUuid: uniqueID,
                fallbackName: navbarEntry.fallbackName,
                pageType: pageType,
                i18nKey: i18nKey,
                pageUuid: uniqueID, //In the case of a customPage this gets overwritten by processCustomPage
                securityTableUseRootRouteAsCookieName: true,
                pageOptions: {}, // setting default object in case there are no options
                fullPath: navbarEntry.fullPath
            },
            meta: {
                fallbackName: navbarEntry.fallbackName,
                i18nKey: i18nKey,
                langs: navbarEntry.lang? (navbarEntry.lang || '').split(',') : null,
                linkReferences: navbarEntry.linkReferences,
                pageType: pageType,
                permissionUserType: navbarEntry.permissionUserType,
                permissionRoles: navbarEntry.permissionRoles,
                policyUuids: navbarEntry.policyUuids,
            }
        };

        if(navbarEntry.relativeRootPath) {
            pageProps.relativeRootPath = `/${navbarEntry.relativeRootPath}`;
            pageProps.props.relativeRootPath = `/${navbarEntry.relativeRootPath}`;
        }

        if(navbarEntry.filterPageContent === true) {
            pageProps.props.filterPageContent = true;
            pageProps.props.pageContentFirmIds = navbarEntry.pageContentFirmIds;
            pageProps.props.pageContentItemType = navbarEntry.pageContentItemType;
            pageProps.props.pageContentItems = navbarEntry.pageContentItems;
            pageProps.props.pageContentRegionFilters = navbarEntry.pageContentRegionFilters;
        }
        if(navbarEntry.options && navbarEntry.options.length > 0) {
            const pageOptions = {};
            navbarEntry.options.forEach((option) => {
                if(option.optionDataType === 'BOOLEAN') {
                    pageOptions[option.optionType] = option.optionValue === 'true';
                } else if (option.optionDataType === 'STRING') {
                    pageOptions[option.optionType] = option.optionValue;
                }

            });
            pageProps.props.pageOptions = pageOptions;
            pageProps.meta.pageOptions = pageOptions;
        }

        let entry = undefined;
        const showSubmenu = (defaultVal) => navbarEntry.submenuDisabled ? false : defaultVal;
        const customGroupPath = () => {
            if(navbarEntry.relativeRootPath){
                return `/${navbarEntry.relativeRootPath}`;
            }
            const filler = '_';
            const relativeRootPath = navbarEntry.fallbackName.toLowerCase().toLowerCase().trim().replace(/[^a-zA-Z0-9_-]/g, filler).split(filler).filter(l => !!l).join(filler);
            return `/${relativeRootPath}`;
        };

        const getStandardCrumbs = () => {
            const defaultCrumbBase = [{path: '/', i18nKey: home.i18nKey || '$vuetify.router.home', text: home.fallbackName || 'home'}];
            const parentCrumbs  = parent && parent.meta && parent.meta.breadcrumbs || [];
            return ([
                ...((parentCrumbs && parentCrumbs.length)? parentCrumbs : defaultCrumbBase),
                {path: `/${navbarEntry.relativeRootPath}`, i18nKey:pageProps.i18nKey, text: pageProps.fallbackName, disabled: navbarEntry.disableView}
            ]);
        };
        const renderPageOrNestedView  = (callback) => navbarEntry.disableView? getCustomPageComponent('GROUP'): callback;
        const adminComponent = (path, component, options = {props: {}, meta: {}}) => {
            const adminID = uuid();
            return ({
                ...options,
                id: adminID,
                name: path,
                path, adminOnly: true, component,
                meta: {
                    adminOnly: true,
                    ...(options.meta || {})
                },
                props: {
                    ...(options.props || {}),
                },
            });
        };
        const redirect = () => navbarEntry.disableView? () => ({path: '/', name: 'home'}) : undefined;
        switch (navbarEntry.pageType) {
        case 'PUBLIC_LANDING_PAGE':
            processCustomPage(navbarEntry, pageProps.props);
            entry = {
                ...pageProps,
                name: 'landing',
                fallbackName: 'Public Pages',
                i18nKey:  '$vuetify.router.login',
                showInNavbar: false,
                subMenuComponent: () => import('../components/GroupSubMenu.vue'),
                exact: true,
                path: '/public',
                public: true,
                component: getCustomPageComponent(pageProps.props.customPageTemplate),
            };
            entry.props.publicPageOptions = entry.props.pageOptions;
            if (pageProps.props.customPageTemplate === 'PUBLIC_LANDING_PAGE_PASS_THROUGH') {
                entry.props.publicPageOptions.DISABLE_PUBLIC_GO_BACK = true;
                entry.props.pageOptions.DISABLE_PUBLIC_GO_BACK = true;
            }
            entry.meta.fallbackName = 'Login';
            entry.meta.i18nKey = '$vuetify.router.login';
            break;
        case 'HOME':
            processCustomPage(navbarEntry, pageProps.props);
            entry = {
                ...pageProps,
                name: 'home',
                showInNavbar: navbarEntry.showInNavbar,
                exact: true,
                path: '/',
                fallbackName: navbarEntry.fallbackName,
                i18nKey:  i18nKey || navbarEntry.fallbackName? i18nKey : '$vuetify.router.home',
                isSubMenu: showSubmenu(false),
                component: getCustomPageComponent(pageProps.props.customPageTemplate || 'HOME_DEFAULT', 'HOME_DEFAULT'),
                meta: {
                    ...pageProps.meta,
                    disableTriggerRender: true,
                    fallbackName: navbarEntry.fallbackName,
                    pageType: 'HOME',
                    i18nKey: i18nKey || navbarEntry.fallbackName? i18nKey : '$vuetify.router.home',
                },
                props: {
                    ...pageProps.props,
                    isHome: true,
                    isDefined: !!pageProps.props.customPageTemplate
                }
            };
            break;
        case 'ADMIN':
            entry = {
                name: 'admin',
                pageType: pageType,
                showInNavbar: true,
                adminOnly: true,
                path: '/admin',
                i18nKey: '$vuetify.router.admin',
                isSubMenu: showSubmenu(false),
                component: () => import('../views/Admin.vue'),
                meta: {
                    ...pageProps.meta,
                    i18nKey: '$vuetify.router.admin',
                },
                redirect: () => ({path: '/admin/users'}),
                children: [
                    adminComponent('users', UserTableV1, {i18nKey: '$vuetify.router.users', pinned: true}),
                    ...(hasAuthority('ROLE_PORTAL_ADMIN') ? [
                        adminComponent('sitedataconfig', SiteDataConfigManagerV1, {i18nKey: '$vuetify.router.siteData',  pinned: true, props: {disableRouter: false}}),
                        adminComponent('navbar', NavbarPageManagerV1, {i18nKey: '$vuetify.router.navbar',  pinned: true}),
                        adminComponent('custompageconfig', PropertyManagerV1, {i18nKey: '$vuetify.router.customPageData', props: {manageType: '$vuetify.router.custompage'}}),
                        adminComponent('domaindataconfig', PropertyManagerV1, {i18nKey: '$vuetify.router.domainData'}),
                        adminComponent('filters', FilterConfigurationManagerV1, {i18nKey: '$vuetify.router.filterConfig'}),
                        adminComponent('policies', ManageCookiePoliciesV1, {i18nKey: '$vuetify.router.policies'}),
                        adminComponent('reports', ReportManagerV1, {i18nKey: '$vuetify.router.reports'}),
                        adminComponent('smarttags', SmartTagManagerV1, {i18nKey: '$vuetify.router.smartTags'}),
                        adminComponent('restrictionlists', RestrictionListManagerV1, {i18nKey: '$vuetify.router.restrictionLists'}),
                        adminComponent('rolespermissions', RolePermissionTableV1, {i18nKey: '$vuetify.router.rolesAndPermissions'}),
                        adminComponent('sellsiderestrictedlist', SellsideRestrictedListV1, {i18nKey: '$vuetify.router.sellsideRestrictedList'}),
                        adminComponent('translations', SiteDataTranslationsV1, {i18nKey: '$vuetify.router.translations'}),
                    ] : []),
                ],
            };
            break;
        case 'REGISTRATION_QUEUE':
            entry = {
                name: 'registrationQueue',
                showInNavbar: true,
                path: '/registrationQueue',
                i18nKey: '$vuetify.router.registrationQueue',
                isSubMenu: showSubmenu(false),
                component: () => import('../views/RegistrationQueue.vue'),
                meta: {
                    ...pageProps.meta,
                    i18nKey: '$vuetify.router.registrationQueue',
                }
            };
            break;
        case 'GROUP':
            entry = {
                ...pageProps,
                path: customGroupPath(),
                isSubMenu: showSubmenu(true),
                subMenuComponent: () => import('../components/GroupSubMenu.vue'),
                component:  getCustomPageComponent(navbarEntry.pageType)
            };
            break;
        case 'GROUP_WITH_TITLE':
        case 'GROUP_WITH_BANNER':
            processCustomPage(navbarEntry, pageProps.props);
            entry = {
                ...pageProps,
                path: customGroupPath(),
                isSubMenu: showSubmenu(true),
                subMenuComponent: () => import('../components/GroupSubMenu.vue'),
                component:  getCustomPageComponent(navbarEntry.pageType),
            };
            if(entry.props?.pageOptions?.PREFIX_PARENT_TO_TITLE){
                entry.props.parent = parent;
            }
            break;
        case 'GROUP_CUSTOM_PAGE':
            processCustomPage(navbarEntry, pageProps.props);
            entry = {
                ...pageProps,
                path: `/${navbarEntry.relativeRootPath}`,
                isSubMenu: showSubmenu(true),
                component: getCustomPageComponent(pageProps.props.customPageTemplate),
                subMenuComponent: () => import('../components/GroupSubMenu.vue'),
            };
            entry.props.name = entry.name;
            entry.props.path = entry.path;
            break;
        case 'ANALYSTS':
            entry = {
                ...pageProps,
                path: `/${navbarEntry.relativeRootPath}`,
                isSubMenu: showSubmenu(!navbarEntry.disableView),
                subMenuComponent: () => import('../components/AnalystSubMenu.vue'),
                component: renderPageOrNestedView(() => import('../views/Analysts.vue')),
                redirect: redirect(),
                singleLink: true, // for when in nested groups
                generateChildCrumbs: true,
                children: [
                    {
                        id: uuid(),
                        name: `SINGLE_ANALYST-${pageProps.id}`,
                        path: ':analyst',
                        public: false,
                        component: () => import('../views/Analyst.vue'),
                        props: {
                            ...pageProps.props,
                            singleDomainPageOnly: !!navbarEntry.disableView,
                            relativeRootPath: `/${navbarEntry.relativeRootPath}`
                        },
                        meta:{
                            pageType: pageType,
                            breadcrumbs: getStandardCrumbs(),
                            linkReferences: navbarEntry.linkReferences
                        }
                    },
                ]
            };
            break;
        case 'COMPANIES':
            entry = {
                ...pageProps,
                path: `/${navbarEntry.relativeRootPath}`,
                isSubMenu: showSubmenu(false),
                component: renderPageOrNestedView(() => import('../views/Companies.vue')),
                redirect: redirect(),
                singleLink: true,// for when in nested groups
                generateChildCrumbs: true,
                children: [
                    {
                        id: uuid(),
                        name: `SINGLE_COMPANY-${pageProps.id}`,
                        path: ':company',
                        public: false,
                        component: () => import('../views/Company.vue'),
                        props: {
                            ...pageProps.props,
                            singleDomainPageOnly: !!navbarEntry.disableView,
                            relativeRootPath: `/${navbarEntry.relativeRootPath}`
                        },
                        meta:{
                            pageType: pageType,
                            breadcrumbs: getStandardCrumbs(),
                            linkReferences: navbarEntry.linkReferences
                        }
                    },
                ]
            };
            break;
        case 'SECTORS':
            entry = {
                ...pageProps,
                path: `/${navbarEntry.relativeRootPath}`,
                isSubMenu: showSubmenu(true),
                subMenuComponent: () => import('../components/SectorSubMenu.vue'),
                component: () => import('../views/OldSectors.vue'),
                redirect: redirect(),
                singleLink: true,// for when in nested groups
                generateChildCrumbs: true,
                children: [
                    {
                        id: uuid(),
                        name: `SINGLE_SECTOR-${pageProps.id}`,
                        path: ':sector',
                        public: false,
                        component: () => import('../views/Sector.vue'),
                        props: {
                            ...pageProps.props,
                            singleDomainPageOnly: !!navbarEntry.disableView,
                            relativeRootPath: `/${navbarEntry.relativeRootPath}`
                        },
                        meta:{
                            pageType: pageType,
                            breadcrumbs: getStandardCrumbs(),
                            linkReferences: navbarEntry.linkReferences
                        }
                    },
                ]
            };
            break;
        case 'SECTORS_V2':
            entry = {
                ...pageProps,
                path: `/${navbarEntry.relativeRootPath}`,
                isSubMenu: showSubmenu(!navbarEntry.disableView),
                subMenuComponent: () => import('../components/SectorSubMenu.vue'),
                component: renderPageOrNestedView(() => import('../views/Sectors.vue')),
                redirect: redirect(),
                singleLink: true,// for when in nested groups
                generateChildCrumbs: true,
                children: [
                    {
                        id: uuid(),
                        name: `SINGLE_SECTORS-${pageProps.id}`,
                        path: ':sector',
                        public: false,
                        component: () => import('../views/Sector.vue'),
                        props: {
                            ...pageProps.props,
                            singleDomainPageOnly: !!navbarEntry.disableView,
                            relativeRootPath: `/${navbarEntry.relativeRootPath}`
                        },
                        meta:{
                            pageType: pageType,
                            breadcrumbs: getStandardCrumbs(),
                            linkReferences: navbarEntry.linkReferences
                        }
                    },
                ]
            };
            break;
        case 'ADVANCED_SEARCH':
            entry = {
                ...pageProps,
                path: `/${navbarEntry.relativeRootPath}`,
                isSubMenu: showSubmenu(false),
                component: () => import('../views/SearchResults.vue')
            };
            entry.props.showSearchResultAsTitle = false;
            entry.props.allowAdvanceSearch = true;
            entry.props.allowAdvanceSearchReload = true;
            entry.props.defaultAdvancedSearchOpen = true;
            entry.props.allowAdvanceSearchSaving = true;
            break;
        case 'REGISTRATION_ATTESTATION_PAGE':
            pageProps.props.formTemplateId = navbarEntry.formTemplateId;
            //fall through
        case 'CUSTOM':
            processCustomPage(navbarEntry, pageProps.props);
            entry = {
                ...pageProps,
                path: `/${navbarEntry.relativeRootPath}`,
                isSubMenu: showSubmenu(false),
                component: getCustomPageComponent(pageProps.props.customPageTemplate)
            };
            break;
        case 'EXTERNAL_URL':
            entry = {
                ...pageProps,
                path: navbarEntry.externalUrl,
                isSubMenu: false
            };
            break;
        }

        if(entry?.props) {
            const convertPath = (path) => (path || '').replaceAll('/', ' ').trim().replaceAll(' ', '-');
            const parentPath = convertPath(parent?.path || '');
            entry.props.pathKey = `${parentPath ? parentPath + '-' : ''}${convertPath(entry.path)}`;
        }

        return entry;
    }
};
